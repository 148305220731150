import React, { ChangeEvent } from 'react';
import { DataSourcePluginOptionsEditorProps } from '@grafana/data';
import { MyDataSourceOptions } from '../types';
import SettingsField from './SettingsField';

interface Props extends DataSourcePluginOptionsEditorProps<MyDataSourceOptions> {}

export function ConfigEditor(props: Props) {
  const {
    onOptionsChange,
    options,
    options: { jsonData },
  } = props;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newJsonData = { ...jsonData, [name]: value };
    onOptionsChange({ ...options, jsonData: newJsonData });
  };

  console.log(jsonData, 'jsonData');

  return (
    <>
      <SettingsField
        value={jsonData.tcpIp}
        handleInputChange={handleInputChange}
        label="TCP IP"
        labelWidth={15}
        tooltip="TCP uri"
        name="tcpIp"
      />
      <SettingsField
        value={jsonData.tcpPort}
        handleInputChange={handleInputChange}
        label="TCP PORT"
        labelWidth={15}
        tooltip="TCP port"
        name="tcpPort"
      />

      <SettingsField
        value={jsonData?.databaseIp}
        handleInputChange={handleInputChange}
        label="Database IP"
        labelWidth={15}
        tooltip="Database IP"
        name="databaseIp"
      />

      <SettingsField
        value={jsonData?.dbName}
        handleInputChange={handleInputChange}
        label="Database name"
        labelWidth={20}
        tooltip="Database name"
        name="dbName"
      />
      <SettingsField
        value={jsonData?.userName}
        handleInputChange={handleInputChange}
        label="Username"
        labelWidth={15}
        tooltip="username"
        name="userName"
      />
      <SettingsField
        value={jsonData?.password}
        handleInputChange={handleInputChange}
        label="Password"
        labelWidth={15}
        tooltip="password"
        name="password"
      />
    </>
  );
}
