import {
  CoreApp,
  DataQueryRequest,
  DataQueryResponse,
  DataSourceInstanceSettings,
  LiveChannelScope,
} from '@grafana/data';
import { DataSourceWithBackend, getGrafanaLiveSrv } from '@grafana/runtime';

import { defaults } from 'lodash';
import { Observable, merge } from 'rxjs';

import { MyQuery, MyDataSourceOptions, DEFAULT_QUERY } from './types';

export class DataSource extends DataSourceWithBackend<MyQuery, MyDataSourceOptions> {
  constructor(instanceSettings: DataSourceInstanceSettings<MyDataSourceOptions>) {
    super(instanceSettings);
    console.log('instanceSettings:', instanceSettings);
  }

  query(request: DataQueryRequest<MyQuery>): Observable<DataQueryResponse> {
    const observables = request.targets.map((target) => {
      const query = defaults(target, DEFAULT_QUERY);
      console.log(request.range);

      // Ensure the time range is taken into account for every query
      const from = request.range?.from.valueOf() ?? new Date().valueOf();
      const to = request.range?.to.valueOf() ?? new Date().valueOf();

      // Construct a path or query string that includes the time range
      const path = `data-source-instance/1/query/${from}-${to}`;

      // Log for debugging purposes
      console.log('Querying path:', path);
      console.log(`Time range: From ${new Date(from).toISOString()} to ${new Date(to).toISOString()}`);

      // Obtain data stream observable based on the constructed path including the time range
      const dataStreamObservable = getGrafanaLiveSrv().getDataStream({
        addr: {
          scope: LiveChannelScope.DataSource,
          namespace: this.uid,
          path: path,
          data: {
            ...query,
            TimeFrom: from,
            TimeTo: to,
          },
        },
      });

      // Log the observable to see what you're getting back
      dataStreamObservable.subscribe({
        next: (response) => console.log('DataStream response:', response),
        error: (error) => console.error('DataStream error:', error),
      });

      return dataStreamObservable;
    });

    return merge(...observables);
  }

  getDefaultQuery(app: CoreApp): Partial<MyQuery> {
    return DEFAULT_QUERY;
  }

  filterQuery(query: MyQuery): boolean {
    if (query.hide) {
      return false;
    }
    return true;
  }
}
