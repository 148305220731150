import { InlineFieldRow, InlineField, Input } from '@grafana/ui';
import React from 'react';

interface SettingsFieldProps {
  value: string | number | undefined;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  tooltip: string;
  labelWidth?: number;
  placeholder?: string;
  name: string;
}

function SettingsField({
  value,
  handleInputChange,
  label,
  tooltip,
  labelWidth = 10,
  placeholder,
  name,
}: SettingsFieldProps) {
  return (
    <InlineFieldRow>
      <InlineField label={label} labelWidth={labelWidth} tooltip={tooltip}>
        <Input
          width={30}
          name={name}
          required
          value={value || ''}
          autoComplete="off"
          placeholder={placeholder || ''}
          onChange={handleInputChange}
        />
      </InlineField>
    </InlineFieldRow>
  );
}

export default SettingsField;
