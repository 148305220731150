import React, { ChangeEvent } from 'react';
import { InlineField, Input, HorizontalGroup } from '@grafana/ui';
import { QueryEditorProps } from '@grafana/data';
import { DataSource } from '../datasource';
import { MyDataSourceOptions, MyQuery } from '../types';

type Props = QueryEditorProps<DataSource, MyQuery, MyDataSourceOptions>;

export function QueryEditor({ query, onChange, onRunQuery }: Props) {
  const onLaneChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...query, lane: event.target.valueAsNumber });
  };

  const onSiteNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...query, siteName: event.target.value });
  };

  const { siteName, lane } = query;

  return (
    <HorizontalGroup>
      <InlineField label="Lane" labelWidth={16} tooltip="Lane">
        <Input onChange={onLaneChange} placeholder="1" onBlur={onRunQuery} value={lane} type="number" />
      </InlineField>
      <InlineField label="Site name" labelWidth={16} tooltip="Site name">
        <Input
          onChange={onSiteNameChange}
          placeholder="Tomacevo_SHM"
          onBlur={onRunQuery}
          value={siteName}
          type="string"
        />
      </InlineField>
    </HorizontalGroup>
  );
}
